import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from "./routes/login";
import Dashboard from "./routes/dashboard";
import Settings from "./routes/settings";
import { AuthProvider, RequireAuth } from 'react-auth-kit'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <RequireAuth loginPath='/'><Dashboard /></RequireAuth>,
  },
  {
    path: "/settings",
    element: <RequireAuth loginPath='/'><Settings /></RequireAuth>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider authType = {'cookie'}
                  authName={'_auth'}
                  cookieDomain={window.location.hostname}
                  cookieSecure={window.location.protocol === "https:"}>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

import React, { useState, useEffect } from 'react';
import {useAuthHeader} from 'react-auth-kit';

const URL = 'clientInfo.json'

const ClientInfo = () => {
  const [clientInfo, setClientInfo] = useState({}); //list d'event list au début
  const auth = useAuthHeader();

  useEffect(() => {
      fetch("https://api.phresque.com/get_api_keys",{
        method: 'get',
        mode: 'cors',
        headers:{
          'Authorization': auth()}
      }).then(response=> {
          if (response.ok) {
            return response.json();
          } else {
            // HANDLE ERROR
            throw new Error('Something went wrong');
          }
        }).then(data => {
          setClientInfo({
            ...clientInfo,
            apiToken_test: data['api_key_test'],
            apiToken_production: data['api_key_prod']
          });
        }).catch((error) => {
          // HANDLE ERROR
          alert('you need to login again')
          console.log(error)
        });
  },clientInfo)

  return (
    <div className='col-span-12 mb-4'>
      <div className='grid grid-cols-12 gap-4 hidden'>
        <div className='md:col-span-4 col-span-12'>
          <div className='block'>
            <label className="text-sm font-medium text-gray-800" htmlFor="email">First Name</label>
          </div>
          <div>
            <input
            className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800 bg-gray-100 cursor-not-allowed'
            id="firstName"
            name="firstName"
            type="text"
            value={clientInfo.firstName}
            disabled="true"
          />
          </div>
        </div>
        <div className='md:col-span-4 col-span-12'>
          <div className='block'>
            <label className="text-sm font-medium text-gray-800" htmlFor="email">Last Name</label>
          </div>
          <div>
            <input
            className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800 bg-gray-100 cursor-not-allowed'
            id="firstName"
            name="firstName"
            type="text"
            value={clientInfo.lastName}
            disabled="true"
          />
          </div>
        </div>
        <div className='md:col-span-4 col-span-12'>
          <div className='block'>
            <label className="text-sm font-medium text-gray-800" htmlFor="email">Company</label>
          </div>
          <div>
            <input
            className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800 bg-gray-100 cursor-not-allowed'
            id="firstName"
            name="firstName"
            type="text"
            value={clientInfo.companyName}
            disabled="true"
          />
          </div>
        </div>
        <div className='md:col-span-4 col-span-12 mb-4'>
          <div className='block'>
            <label className="text-sm font-medium text-gray-800" htmlFor="email">Email</label>
          </div>
          <div>
            <input
            className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800 bg-gray-100 cursor-not-allowed'
            id="firstName"
            name="firstName"
            type="text"
            value={clientInfo.emailAddress}
            disabled="true"
          />
          </div>
        </div>
        <div className='md:col-span-4 col-span-12 mb-4'>
          <div className='block'>
            <label className="text-sm font-medium text-gray-800" htmlFor="email">Phone Number</label>
          </div>
          <div>
            <input
            className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800 bg-gray-100 cursor-not-allowed'
            id="firstName"
            name="firstName"
            type="phone"
            value={clientInfo.phoneNumber}
            disabled="true"
          />
          </div>
        </div>
      </div>


      <div className='grid grid-cols-12 gap-4 border-b'>
        <div className='md:col-span-6 col-span-12 mb-4'>
          <div className='block'>
            <label className="text-sm font-medium text-gray-800" htmlFor="email">Test Token</label>
          </div>
          <div className='mb-3'>
            <input
            className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800'
            id="firstName"
            name="firstName"
            type="phone"
            disabled="true"
            value={clientInfo.apiToken_test}
          />
          </div>
        </div>
        <div className='md:col-span-6 col-span-12 mb-4'>
          <div className='block'>
            <label className="text-sm font-medium text-gray-800" htmlFor="email">Production Token</label>
          </div>
          <div className='mb-3'>
            <input
            className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800'
            id="firstName"
            name="firstName"
            type="phone"
            disabled="true"
            value={clientInfo.apiToken_production}
          />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientInfo;

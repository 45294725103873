import { Link } from "react-router-dom";
import Logo from '../img/phresque_square.png';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    signOut();
    navigate('/');
  }

  return (
    <div className="border-b border-slate-200 px-6 py-3 sticky top-0 bg-white">
      <div className="flex">
        <div className="shrink pr-4">
          <img src={Logo} width={60} height={60} />
        </div>
        <div className="grow pt-2.5">
          <div className="inline pr-2">
            <Link className="rounded-md px-3 py-2 text-gray-800 text-base font-semibold	ease-in-out duration-300 inline-block hover:bg-gray-100" to={'/dashboard'}><span>Dashboard</span></Link>
          </div>
          <div className="inline">
            <Link className="bg-white hover:bg-gray-100 rounded-md px-3 py-2 text-gray-800 text-base ease-in-out duration-300 inline-block" to={'/settings'}><span>Settings</span></Link>
          </div>
        </div>
        <div className="shrink pt-2.5">
        <Link className="bg-white hover:bg-gray-100 rounded-md px-3 py-2 text-gray-800 text-base ease-in-out duration-300 inline-block" onClick={logout}><span>Log Out</span></Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

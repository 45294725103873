const EventPage = ({
  eventName,
  startDate,
  endDate,
  detailEvent}) => {

  const totalTransformations = detailEvent.reduce(
    (currValue, ev) => currValue+ev.totalTransformations,
    0
  )
  const totalAmount = detailEvent.reduce(
    (currValue, ev) => currValue+ev.totalTransformations*ev.unitPrice,
    0
  )

  return (
    <div>
      <div className="bg-gray-100 p-3 border-b border-slate-200 text-base font-semibold text-gray-800">Event Detail</div>
      <div className="p-6">

      <div className='pb-2 font-semibold text-gray-800 text-sm'>Event Informations</div>
    <table className="table-auto text-sm mb-6">
      <tbody>
      <tr>
        <td className="text-gray-400 pr-4">Event Name:</td>
        <td className="text-gray-800">{eventName}</td>
      </tr>
      <tr>
        <td className="text-gray-400 pr-4">Period:</td>
        <td className="text-gray-800">{startDate} to {endDate}</td>
      </tr>
      <tr>
        <td className="text-gray-400 pr-4">Total Transformations:</td>
        <td className="text-gray-800">{totalTransformations} transformations ({totalAmount}€)</td>
      </tr>
      </tbody>
    </table>

    <div className='pb-2 font-semibold text-gray-800 text-sm'>Transformation Detail</div>

    {detailEvent.map((ev,i) => {
              return(
    <div className="rounded-md bg-white border border-slate-200 p-4 mb-2">
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-6 col-span-12 border-r border-slate-200">
          <div className="font-semibold text-gray-800 text-sm pb-0.5">{ev.filterName}</div>
          <div className="text-gray-400 text-xs">{ev.description}</div>
        </div>
        <div className="md:col-span-2 col-span-12 border-r border-slate-200">
          <div className="text-gray-400 text-xs pb-0.5">Transformation #</div>
          <div className="text-gray-800 text-sm">{ev.totalTransformations}</div>
        </div>
        <div className="md:col-span-2 col-span-12 border-r border-slate-200">
          <div className="text-gray-400 text-xs pb-0.5">Price per Unit</div>
          <div className="text-gray-800 text-sm">{ev.unitPrice} €</div>
        </div>
        <div className="md:col-span-2 col-span-12">
          <div className="text-gray-400 text-xs pb-0.5">Total Price</div>
          <div className="text-gray-800 text-sm">{ev.unitPrice * ev.totalTransformations} €</div>
        </div>
      </div>
    </div>
)
})}
       
      </div>
    </div>
  );
}

export default EventPage;

import Skeleton from "../skeleton";
import ClientInfo from "../components/clientInfo";
import BillingInfo from "../components/billingInfo";

const Settings = () => {
  return (
      <Skeleton>
        <div className="flex justify-center">
          <div className='grid grid-cols-12 gap-4 m-8 max-w-4xl	'>
            <div className='col-span-12 mb-4'>
              <h1 className='text-xl font-semibold text-gray-800'>Account & Token</h1>
              <div className='grid grid-cols-12 gap-4'>
                <div className='col-start-1 col-span-12 md:col-span-4'>
                  <p className='text-sm text-gray-800 text-gray-400'>Review your profile and your API tokens.</p>
                </div>
              </div>
            </div>

            <ClientInfo />


            <div className='col-span-12 mb-4 hidden'>
              <h1 className='text-xl font-semibold text-gray-800'>Invoices</h1>
              <div className='grid grid-cols-12 gap-4'>
                <div className='col-start-1 col-span-12 md:col-span-4'>
                  <p className='text-sm text-gray-800 text-gray-400'>You can download all your invoices from here.</p>
                </div>
              </div>
            </div>
            <BillingInfo />
            
          </div>
        </div>
      </Skeleton>
  );
}

export default Settings;

import Navbar from "../components/navbar";

const Skeleton = ({children}) => {
  return (
    <div>
      <Navbar />
      {children}
    </div>
  );
}

export default Skeleton;

const Event = ({
  eventName,
  startDate,
  endDate,
  detailEvent,
  expand
}) => {

  const totalTransformations = detailEvent.reduce(
    (currValue, ev) => currValue+ev.totalTransformations,
    0
  )
  const totalAmount = detailEvent.reduce(
    (currValue, ev) => currValue+ev.totalTransformations*ev.unitPrice,
    0
  )

  return (
    <tr className="border-b border-slate-200 hover:bg-blue-50">
      <td className="px-4 py-2">
        <div className="font-semibold text-gray-800 text-sm pb-0.5 capitalize">{eventName}</div>
        <div className="text-gray-400 text-xs">{startDate} to {endDate}</div>
      </td>
      <td className="px-4 py-2 text-center">
        <div className="text-gray-800 text-sm">{totalTransformations} transformations ({totalAmount}€)</div>
      </td>
      <td className="px-4 py-2 text-right">
        <div className="text-sky-500 hover:text-sky-700 text-sm cursor-pointer ease-in-out duration-300" onClick={expand}>Expand</div>
      </td>
    </tr>
  );
}

export default Event;

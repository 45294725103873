import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useAuthUser, useAuthHeader} from 'react-auth-kit';

import Skeleton from "../skeleton";
import Dropdown from "../components/dropDown";
import KPIBox from "../components/KPIBox";
import Event from "./event";
import EventPage from "../components/eventPage";

const URL = 'data.json'

const Dashboard = () => {
  const [evId, showEvent] = useState(-1);
  const [eventList, setEventList] = useState([]); //list d'event list au début
  const authuser = useAuthUser();
  const auth = useAuthHeader();

  useEffect(() => {
      fetch("https://api.phresque.com/get_events",{
        method: 'get',
        mode: 'cors',
        headers:{
          'Authorization': auth()}
      }).then(response=> {
          if (response.ok) {
            return response.json();
          } else {
            // HANDLE ERROR
            throw new Error('Something went wrong');
          }
        }).then(data => {
          setEventList(data);
        }).catch((error) => {
          // HANDLE ERROR
          alert('you need to login again')
          console.log(error)
        });
  },[])

  return (
      <Skeleton>

        <div className="flex justify-center">
        <div className='grid grid-cols-12 gap-4 m-8 max-w-4xl	'>
          <div className='col-span-12 mb-4'>
            <h1 className='text-xl font-semibold text-gray-800 capitalize'>Hello {authuser().username} 👋</h1>
            <div className='grid grid-cols-12 gap-4'>
              <div className='col-start-1 col-span-12 md:col-span-4'>
                <p className='text-sm text-gray-800 text-gray-400'>Review your stats. You can change the period shown by using the filter below</p>
              </div>
            </div>
          </div>
          <div className='col-span-12'>
            <a className='text-sky-500 hover:text-sky-700 text-sm cursor-pointer ease-in-out duration-300 underline'>Viewing Total Period </a>
      
          </div>

            {/* <Dropdown /> */}
            <KPIBox
              id={1}
              title={"transformations"}
              number={eventList.reduce((acc,curr) => {
                return acc + curr.detailEvent.reduce((a,c) => (a+c.totalTransformations),0)
              },0)}
            />
            <KPIBox
              id={2}
              title={"montant"}
              number={eventList.reduce((acc,curr) => {
                return acc + curr.detailEvent.reduce((a,c) => (a+c.totalTransformations*c.unitPrice),0)
              },0)}
            />
            <KPIBox
              id={3}
              title={"filtres"}
              number={eventList.reduce((acc,curr) => {
                return acc + curr.detailEvent.reduce((a,c) => (a+1),0)
              },0)}
            />

            <div className='col-start-1 col-span-12'>
              <div className='border-b border-slate-200 pb-2 font-semibold text-gray-800 text-sm'>List of Events</div>
              <table className="table-auto w-full rounded-md">
                <tbody>
                  
                  {eventList.map((e,i) => {
                    return (
                      <Event
                        expand={() => showEvent(i)}
                        {...e}/>)
                  })}
                </tbody>
              </table>

            {(evId < 0) ? null :
              <div className="bg-white drop-shadow rounded-md mt-4">
                <EventPage
                  {...eventList[evId]}/>
                <div className="text-rose-600 absolute right-3 top-3" onClick={() => showEvent(-1)}>/close icon/</div>
              </div>
            }
            </div>

            
        </div>
        </div>
      
      </Skeleton>
  );
}

export default Dashboard;
const KPIBox = ({title,number}) => {
  return (
    <div className='md:col-span-4 col-span-12 mb-4'>
      <div className="border border-slate-200 rounded-md p-4 bg-white">
        <div className="uppercase text-xs text-gray-400 pb-2">Total {title}</div>
        <div className="text-xl font-semibold	text-gray-800">{number}</div>
      </div>
    </div>
  );
}

export default KPIBox;

const billingInfo = () => {
  return (
    <div className='col-span-12 mb-4 hidden'>
    <div className='grid grid-cols-12 gap-4'>
        <div className='md:col-span-6 col-span-12'>
          
      <table className="table-auto w-full rounded-md overflow-hidden">
        <thead className="bg-gray-100">
          <tr>
            <th className="font-semibold text-gray-400 text-sm text-left px-4 py-2 border-b border-slate-200">Date</th>
            <th className="font-semibold text-gray-400 text-sm text-left px-4 py-2 border-b border-slate-200">Invoice Number</th>
            <th className="font-semibold text-gray-400 text-sm text-center px-4 py-2 border-b border-slate-200"></th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-slate-200 hover:bg-blue-50">
            <td className="px-4 py-2 text-gray-800 text-sm ">January 2023</td>
            <td className="px-4 py-2 text-gray-800 text-sm">DO-OO4</td>
            <td className="px-4 py-2 text-gray-800 text-sm">
              <div className="text-sky-500 hover:text-sky-700 text-sm cursor-pointer ease-in-out duration-300" >PDF</div>
            </td>
          </tr>
          <tr className="border-b border-slate-200 hover:bg-blue-50">
            <td className="px-4 py-2 text-gray-800 text-sm">December 20222</td>
            <td className="px-4 py-2 text-gray-800 text-sm">DO-OO3</td>
            <td className="px-4 py-2 text-gray-800 text-sm">
              <div className="text-sky-500 hover:text-sky-700 text-sm cursor-pointer ease-in-out duration-300" >PDF</div>
            </td>
          </tr>
          <tr className="border-b border-slate-200 hover:bg-blue-50">
            <td className="px-4 py-2 text-gray-800 text-sm">November 20222</td>
            <td className="px-4 py-2 text-gray-800 text-sm">DO-OO2</td>
            <td className="px-4 py-2 text-gray-800 text-sm">
              <div className="text-sky-500 hover:text-sky-700 text-sm cursor-pointer ease-in-out duration-300" >PDF</div>
            </td>
          </tr>
          <tr className="border-b border-slate-200 hover:bg-blue-50">
            <td className="px-4 py-2 text-gray-800 text-sm">October 20222</td>
            <td className="px-4 py-2 text-gray-800 text-sm">DO-OO1</td>
            <td className="px-4 py-2 text-gray-800 text-sm">
              <div className="text-sky-500 hover:text-sky-700 text-sm cursor-pointer ease-in-out duration-300" >PDF</div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      </div>
    </div>
  );
}

export default billingInfo;

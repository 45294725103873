import React from 'react';
import { useFormik } from 'formik';
import { useSignIn } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

import Logo from '../img/phresque_square.png';
import Background from '../img/phresque_bg.png';

 // A custom validation function. This must return an object
 // which keys are symmetrical to our values/initialValues
 const validate = values => {
   const errors = {};
   if (!values.password) {
     errors.password = 'Required';
   } else if (values.password.length > 15) {
     errors.password = 'Must be 15 characters or less';
   }


   if (!values.username) {
     errors.username = 'Required';
   }

   return errors;
 };

 const Login = () => {
   // Pass the useFormik() hook initial form values, a validate function that will be called when
   // form values change or fields are blurred, and a submit function that will
   // be called when the form is submitted
  const signIn = useSignIn();
  const navigate = useNavigate();
   const formik = useFormik({
     initialValues: {
       password: '',
       username: '',
     },
     validate,
     onSubmit: values => {
        //alert(JSON.stringify(values, null, 2));
        const REST_API_URL = 'https://api.phresque.com/auth';
        fetch(REST_API_URL, {
          method: 'post',
          mode: 'cors',
          headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
          body: JSON.stringify(values)
        }).then(response=> {
          if (response.ok) {
            return response.json();
          } else {
            // HANDLE ERROR
            throw new Error('Something went wrong');
          }
        }).then(data => {
          // HANDLE RESPONSE DATA
          //on est loggé
          signIn({
            token: data.access_token,
            expiresIn: 3600,
            tokenType: "JWT",
            authState: { 'username': values.username }
          });
          navigate('/dashboard')
        }).catch((error) => {
          // HANDLE ERROR
          alert('wrong password')
          console.log(error)
        });
     },
   });
   return (
     <form onSubmit={formik.handleSubmit}>

    <div className="flex justify-center rounded-md max-w-4xl h-screen md:h-fit md:m-5 md:mr-auto md:ml-auto" style={{ backgroundImage:`url(${Background})` }}>

  
          <div className='bg-white drop-shadow rounded-md w-3/4 md:w-1/3 h-fit p-6 m-12 mr-auto ml-auto'>
            <div className='text-center py-4'>
              <div className='inline-block'>
                <img src={Logo} width={100} height={100} />
              </div>

              
            </div>

            <div className='block'>
              <label className="text-sm font-medium text-gray-800" htmlFor="username">Username</label>
            </div>
            <div className='mb-3'>
              <input
              className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800'
              id="username"
              placeholder='username'
              name="username"
              type="username"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
            {formik.errors.username ? <div className='text-red-500 text-xs'>{formik.errors.username}</div> : null}
            </div>
          
            <div className='block'>
              <label className="text-sm font-medium text-gray-800" htmlFor="password">Password</label>
            </div>
            <div className='mb-6'>
              <input
              className='rounded-md px-3 py-1.5 w-full text-sm border-solid border border-slate-200 text-gray-800'
              id="password"
              placeholder='*********'
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik.errors.password ? <div className='text-red-500 text-xs'>{formik.errors.password}</div> : null}
            </div>
          
          <button className='bg-sky-500 hover:bg-sky-700 rounded-md px-3 py-1.5 w-full text-white text-sm mb-3 ease-in-out duration-300' type="submit">Login</button>
          <button className='bg-gray-100 hover:bg-gray-300 rounded-md px-3 py-1.5 w-full text-gray-800 text-sm mb-6 ease-in-out duration-300' type="submit">Forgot Password</button>

          <p className='text-xs text-gray-400 text-center	'>@Phresque 2022. All rights reserved</p>
          </div>
          
     


      </div>
     </form>
   );
 };

export default Login;
